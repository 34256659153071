import React, { useContext } from "react";
import { ShopContext } from "../../shoplogic/shoplogic";

export const Product = (props) => {
  const { id, productName, price, price2, productImage } = props.data;
  const { addToCart, cartItems } = useContext(ShopContext);
  const cartItemCount = cartItems[id];

  return (
    <div className="product">
      <br></br>
      <br></br>
      <img src={productImage} />
      <div className="description">
        <p>
          <b>{productName}</b>
        </p>

        <hr></hr>
        <p> £{price}</p>
        <p> Lease from £{price2} per month</p>

        <button
          className="addToCartBttn"
          onClick={() => addToCart(id)}
          id="addbutton"
        >
          Order {cartItemCount > 0 && <> ({cartItemCount})</>}
        </button>
      </div>
    </div>
  );
};
