import { Navbar } from "./components/navbar";
import React, { Component } from "react";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Shop } from "./pages/shop/shop";
import { Cart } from "./pages/cart/cart";
import { ShopContextProvider } from "./shoplogic/shoplogic";
import "./App.css";
import "./index.js";

function App() {
  return (
    <div className="App">
      <ShopContextProvider>
        {" "}
        <Router>
          <Navbar />

          <img
            id="hero"
            className="hero-pic"
            src="https://images.unsplash.com/photo-1593941707882-a5bba14938c7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2944&q=80"
            alt=""
          />

          <switch>
            {" "}
            <Routes>
              <Route path="/" element={<Shop />} />
              <Route path="/cart" element={<Cart />} />
            </Routes>
          </switch>
        </Router>
      </ShopContextProvider>
    </div>
  );
}

export default App;
