import React, { useContext } from "react";
import { ShopContext } from "../../shoplogic/shoplogic";
import { PRODUCTS } from "../../products";
import { CartItem } from "./cart-item.jsx";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import "./cart.css";
export const Cart = () => {
  const { cartItems, getTotalCartAmount, checkout } = useContext(ShopContext);
  const totalAmount = getTotalCartAmount();

  const navigate = useNavigate();

  return (
    <div className="cart">
      <div>
        <h1>Your Cart</h1>
      </div>
      <div className="cart">
        {PRODUCTS.map((product) => {
          if (cartItems[product.id] !== 0) {
            return <CartItem data={product} />;
          }
        })}
      </div>

      {totalAmount > 0 ? (
        <div className="checkout">
          <p> Total: £{totalAmount} </p>
          <ButtonGroup variant="contained" disableElevation size="large">
            <Button onClick={() => navigate("/")}>Continue Shopping</Button>
            <Button
              onClick={() => {
                checkout();
                navigate("/checkout");
              }}
            >
              Checkout
            </Button>
          </ButtonGroup>
        </div>
      ) : (
        <h1> Cart is empty</h1>
      )}
    </div>
  );
};
