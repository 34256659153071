import React from "react";
import { Link } from "react-router-dom";
import { ShoppingCart } from "phosphor-react";
import { Lightning } from "phosphor-react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import "./navbar.css";

function handleNavbarClick() {
  const hero = document.getElementById("hero");

  hero.style.display = "none";
}

function handleLogoClick() {
  const hero = document.getElementById("hero");
  hero.style.display = "block";
}
export const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbrand" onClick={handleLogoClick}>
        <Lightning size={64} weight="bold" />
        <b>Electrifys</b>
      </div>
      <div className="links">
        <Link to="/" onClick={handleNavbarClick}>
          {" "}
          Shop{" "}
        </Link>
        <Link to="/cart">
          <ShoppingCart size={40} />{" "}
        </Link>
      </div>
    </div>
  );
};
