import jag from "./assets/cars/jag.jpeg";
import tesla from "./assets/cars/tesla3.avif";
import polestar from "./assets/cars/polestar.avif";
import ford from "./assets/cars/ford.avif";
import bmw from "./assets/cars/bmw.jpeg";
import kia from "./assets/cars/kia.avif";
import audi from "./assets/cars/audi.avif";
import benz from "./assets/cars/mercedes.avif";
import ion from "./assets/cars/ioniq.avif";
import modely from "./assets/cars/modely.avif";
import gen from "./assets/cars/genesis.avif";
import born from "./assets/cars/cupra.avif";
import meg from "./assets/cars/megane.avif";
import kona from "./assets/cars/kona.avif";
import vw from "./assets/cars/vw.jpg";

export const PRODUCTS = [
  {
    id: 1,
    productName: "Jaguar Ipace",
    price: 45000,
    price2: 400,
    productImage: jag,
  },
  {
    id: 2,
    productName: "Tesla Model 3",
    price: 41000,
    price2: 400,
    productImage: tesla,
  },
  {
    id: 3,
    productName: "Polestar 2",
    price: 38000,
    price2: 380,
    productImage: polestar,
  },

  {
    id: 4,
    productName: "Ford Mustang Mach-E",
    price: 52000,
    price2: 500,
    productImage: ford,
  },

  {
    id: 5,
    productName: "BMW I4",
    price: 43000,
    price2: 400,
    productImage: bmw,
  },

  {
    id: 6,
    productName: "KIA EV6",
    price: 38500,
    price2: 350,
    productImage: kia,
  },

  {
    id: 7,
    productName: "Audi Q4 Etron",
    price: 58500,
    price2: 550,
    productImage: audi,
  },
  {
    id: 8,
    productName: "Mercedes EQS",
    price: 60000,
    price2: 600,
    productImage: benz,
  },
  {
    id: 9,
    productName: "Hyundai Ioniq5",
    price: 30000,
    price2: 350,
    productImage: ion,
  },

  {
    id: 10,
    productName: "Tesla Model Y",
    price: 46000,
    price2: 450,
    productImage: modely,
  },
  {
    id: 11,
    productName: "Genesis GV60",
    price: 58000,
    price2: 500,
    productImage: gen,
  },

  {
    id: 12,
    productName: "Cupra Born",
    price: 38000,
    price2: 350,
    productImage: born,
  },
  {
    id: 13,
    productName: "Renault Megane E-Tech",
    price: 36000,
    price2: 350,
    productImage: meg,
  },

  {
    id: 14,
    productName: "Hyundai Kona",
    price: 39000,
    price2: 350,
    productImage: kona,
  },
  {
    id: 15,
    productName: "Volkswagen ID.7",
    price: 49000,
    price2: 450,
    productImage: vw,
  },
];
